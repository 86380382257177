<template>
  <div class="page">
    <Navbar isBack="true" title="遇见福利"/>
    <div class="page_box">
      <div class="vip_level_nav">遇见福利</div>
      <div class="vip_level_dec">
        玩家注册平台账号，是您与平台相遇的日子，当您VIP等级达到V7及以上，每年的当天系统将会自动为您的账户上发送相遇福利，VIP所对应的相遇福利如下：
      </div>
      <div class="vip_level_dec vip_level_meet">
        相遇时间：2022-08-04 13：20：44
      </div>
      <div class="vip_level_table">
        <div class="vip_level_tr">
          <div class="vip_level_tb">VIP等级</div>
          <div class="vip_level_tb">遇见福利礼包</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">0~6</div>
          <div class="vip_level_tb">无</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">7</div>
          <div class="vip_level_tb">价值180000VND的优惠券</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">8</div>
          <div class="vip_level_tb">价值360000VND的优惠券</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">9</div>
          <div class="vip_level_tb">价值720000VND的优惠券</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">10</div>
          <div class="vip_level_tb">价值1800000VND的优惠券</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data(){
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
<style scoped lang="scss">
$vip-color: #c37c00;
.page_box {
  padding: 0 0.22rem 1rem 0.22rem;
}
.vip_level_nav {
  margin: 0.4rem 0 0.1rem 0;
  padding: 0 0.3rem;
  line-height: 0.4rem;
  border: 1px solid $vip-color;
  color: $vip-color;
  border-radius: 1rem;
}
.vip_level_img {
  margin: 0.3rem 0;
}
.vip_level_dec {
  line-height: 0.4rem;
  font-size: 0.23rem;
}
.vip_level_meet{
  font-weight: bold;
  margin-top: .5rem;
}
.vip_level_table {
  margin-top: 0.3rem;
  width: 100%;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.vip_level_tr {
  display: flex;
}
.vip_level_tr:nth-child(1) {
  background-color: #fde2b0;
}
.vip_level_tr:nth-child(1) .vip_level_tb {
  height: 0.9rem;
  color: $vip-color;
}
.vip_level_tb {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 0.6rem;
  font-size: 0.23rem;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.vip_level_tr .vip_level_tb:nth-child(2){
  flex: 2;
}
</style>